<template>
	<page-container class="index" :page="page">
		<heading-image v-if="page.headingImage" type="large">
			<hero-image>
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p class="hero-title">
						{{ defaults[locale].homepage.headingImageTitle }}
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
				</div>
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>
		</heading-image>

		<main-content>
			<div class="grid main-intro">
				<div class="animated-logo-wrapper">
					<div class="animated-logo-inner" />
					<div class="animated-logo-outer" />
				</div>
				<div class="intro-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
				<div class="intro-text">
					<h2 class="subtitle">
						{{ page.subtitle }}
					</h2>
					<h1>{{ page.title || page.header }}</h1>
					<div v-parse-links v-html="page.content" />
				</div>
			</div>
		</main-content>

		<section class="promoblocks">
			<div class="row">
				<div class="columns column12 align-center header-row">
					<h2>{{ $t('ourMenus') }}</h2>
				</div>
			</div>
			<promoblocks :items="promoblocksData" />
		</section>

		<section v-if="defaults[locale].homepage.sectionReservationsTitle" class="section-reservations">
			<div class="row">
				<div class="columns column8">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.sectionReservationsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.sectionReservationsTitle }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.sectionReservationsContent" />
				</div>
				<div class="columns column4">
					<nuxt-link class="button" :to="localePath('contact')">
						{{ $t('contactUs') }}
					</nuxt-link>
				</div>
			</div>
		</section>

		<section v-if="defaults[locale].homepage.promotionBackgroundImage" class="section-promotion">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.promotionsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.promotionsHeader }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.openingHours" />
					<div v-parse-links v-html="defaults[locale].homepage.promotionsContent" />
				</div>
			</div>
			<picture>
				<source :srcset="defaults[locale].homepage.promotionBackgroundImageWebp" type="image/webp" />
				<source :srcset="defaults[locale].homepage.promotionBackgroundImage" />
				<img
					loading="lazy"
					class="promotions-image"
					:src="defaults[locale].homepage.promotionBackgroundImage"
				/>
			</picture>
		</section>

		<contentblocks :items="page.contentblocks" class="section-contentblocks">
			<template #blocks-header>
				<div class="row header-row align-center">
					<div class="columns column12">
						<h2 class="subtitle">
							{{ defaults[locale].homepage.sectionContentblocksSubtitle }}
						</h2>
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
					</div>
				</div>
			</template>
		</contentblocks>

		<section v-if="defaults[locale].homepage.contactHeader" class="content-section section-contact contact-info">
			<div class="row header-row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.contactSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].homepage.contactHeader }}
					</h2>
				</div>
			</div>
			<div class="row maps-row">
				<div class="columns column5">
					<div v-parse-links v-html="defaults[locale].homepage.contactContent" />
					<h3>{{ defaults[locale].website.restaurantName }}</h3>
					<p>
						{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}<br />
						{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}<br />
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
							{{ defaults[locale].website.phone }} </a
						><br />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon class="icon" icon="fal fa-envelope" size="1x" />
							{{ defaults[locale].website.email }}
						</a>
					</p>
					<br />
					<h3 v-if="defaults[locale].homepage.openingHours">{{ $t('openingHours') }}</h3>
					<div v-parse-links v-html="defaults[locale].homepage.openingHours" />
				</div>
				<div class="columns column7">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.9361726229745!2d4.880666876970416!3d52.371577347207385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609c7b5d6eacf%3A0x90a85be8567337ce!2sCaf%C3%A9%20Restaurant%20Verlan!5e0!3m2!1snl!2snl!4v1690882266900!5m2!1snl!2snl"
						width="100%"
						height="450"
						frameborder="0"
						style="border: 0"
						allowfullscreen=""
						aria-hidden="false"
						tabindex="0"
					/>
				</div>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>

		<section class="also-visit">
			<div class="row header-row align-center">
				<h2>{{ $t('alsoVisit') }}</h2>
			</div>
			<div class="row align-center affiliate-links">
				<a href="https://www.cafepanache.nl/" target="_blank">
					<img src="~/assets/images/logo-cafepanache-full-white.png" alt="Cafe Panache" loading="lazy" />
				</a>
				<a href="https://www.finddoor74.com/" target="_blank">
					<img src="~/assets/images/logo-door74-full-color.png" alt="Door 74" loading="lazy" />
				</a>
			</div>
		</section>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 50%;
	display: flex;
	flex-flow: column nowrap;
	color: #fff;
	max-width: 1200px;
	text-align: center;
	z-index: 5;
	left: 20px;
	right: 20px;
	margin: auto;
	transform: translateY(calc(-50% + 100px));
	animation: fade-from-bottom 1.5s ease;

	.hero-title {
		width: 100%;
		font-weight: 700;
		text-transform: uppercase;
		color: #fff;
		font-size: 56px;
		margin-bottom: 10px;
		line-height: normal;
		text-align: center;
	}

	:deep(> *) {
		margin: 0 0 10px;
	}
}

.main-intro {
	margin: auto;
	grid-template-columns: 5fr 7fr;
	gap: 80px;
	position: relative;
	padding-top: 35px;

	.intro-text {
		padding-top: 75px;

		.subtitle {
			margin-top: 50px;
		}
	}
}

.section-reservations {
	padding: 100px 0;
	background-color: var(--primary-background-color);

	.row {
		background: var(--footer-background-color);
		color: #fff;
		padding: 50px;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;

		.column4 {
			text-align: right;
		}

		h2,
		h3 {
			color: #fff;
		}
	}

	.form-wrapper {
		padding: 40px 30px;
		background: var(--secondary-background-color);

		.button {
			width: auto;
			margin: 10px 0 0;
			padding: 15px 30px;
		}

		input,
		select,
		textarea {
			color: #fff;
			width: 100%;
			max-width: 500px;
			padding: 20px;
			border: none;
			box-shadow: 0 0 5px rgba(0 0 0 / 10%);
			font-size: 13px;
			font-family: var(--body-font-family);
		}
	}

	.reservation-form {
		display: flex;
		flex-flow: row wrap;
		gap: 25px;

		> div {
			width: calc(50% - 13px);
		}
	}
}

.affiliate-links {
	text-align: center;
	display: flex;
	flex-flow: row nowrap;
	place-content: stretch center;
	margin: 0 auto 100px;

	a {
		background: var(--footer-background-color);
		padding: 35px 60px;
		margin: 0;
		display: flex;
		flex-flow: row wrap;
		place-content: center center;

		img {
			max-width: 197px;
		}

		&:last-child {
			img {
				max-width: 177px;
			}
		}
	}
}

.maps-row {
	background: var(--secondary-background-color);
	padding: 10px;
	display: flex;
	flex-flow: row wrap;
	place-content: center center;

	h3 {
		margin: 5px 0 20px;
	}

	.column7 {
		padding: 0;
	}

	.column5 {
		padding: 70px;
	}

	iframe {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.section-promotion {
	background: var(--secondary-background-color);
	margin: 0;
	padding: 225px 25px;
	overflow: auto;
	clip-path: inset(0);
	position: relative;

	&::after {
		background: rgb(0 0 0 / 25%);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		content: '';
	}

	.row {
		position: relative;
		z-index: 10;
		color: #fff;
		overflow: hidden;
		max-width: 800px;
	}

	.promotions-image {
		position: absolute;
		object-fit: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	h2 {
		color: #fff;
	}

	:deep(h2) {
		color: #fff;
	}
}

.section-contentblocks {
	.header-row {
		margin-bottom: 30px;

		h2:not(.subtitle) {
			background: var(--primary-background-color);
			color: var(--primary-background-color);
		}
	}
}

.animated-logo-wrapper {
	position: absolute;
	top: -75px;
	left: calc(50% - 160px);
	width: 202px;
	height: 202px;
	z-index: 3;

	.animated-logo-inner {
		background: url('~/assets/images/animated-logo-inner.png') no-repeat center center;
		background-size: 202px;
		width: 202px;
		height: 202px;
		position: relative;
		z-index: 3;
	}

	.animated-logo-outer {
		background: url('~/assets/images/animated-logo-outer.png') no-repeat center center;
		background-size: 202px;
		width: 202px;
		height: 202px;
		position: absolute;
		inset: 0;
		z-index: 2;
		animation: rotating 9s linear infinite;
	}
}

.section-contact {
	.row:first-child {
		padding-bottom: 50px;
	}
}

@keyframes rotating {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes fade-from-bottom {
	0% {
		opacity: 0;
		transform: translateY(calc(-30% + 100px));
	}

	66% {
		opacity: 0;
	}

	100% {
		opacity: 1;
		transform: translateY(calc(-50% + 100px));
	}
}

@media (max-width: 1080px) {
	.hero-content {
		max-width: 650px;
	}

	.main-intro {
		grid-template-columns: 100%;

		.intro-text {
			padding-top: 0;
		}
	}

	.intro-image {
		display: none;
	}

	.animated-logo-wrapper {
		top: -50px;
		left: 0;
		width: 101px;
		height: 101px;
		z-index: 3;

		.animated-logo-inner {
			background: url('~/assets/images/animated-logo-inner.png') no-repeat center center;
			background-size: 101px;
			width: 101px;
			height: 101px;
		}

		.animated-logo-outer {
			background: url('~/assets/images/animated-logo-outer.png') no-repeat center center;
			background-size: 101px;
			width: 101px;
			height: 101px;
		}
	}
}

@media (max-width: 900px) {
	.section-reservations {
		.row {
			padding: 50px 25px;

			.column4 {
				width: 100%;
				text-align: center;
			}
		}
	}

	.maps-row {
		.columns {
			width: 100%;
		}

		.column5 {
			padding: 50px 20px;
		}

		iframe {
			min-height: 400px;
		}
	}

	.affiliate-links {
		a {
			padding: 20px;
		}
	}

	.section-promotion {
		padding: 150px 25px;
	}

	.promotions-image {
		position: absolute;
	}

	.hero-content {
		font-size: 15px;
		padding: 0 10px;

		.hero-title {
			font-size: 30px;
		}
	}

	.section-reservations .reservation-form > div {
		width: 100%;
	}
}
</style>
